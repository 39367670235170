import Web3 from "web3";
import big from "big.js";
import { ethers } from "ethers";
// tb合约pg合约方法
export default {
// 
async getUserTotalTokenValue(currentAddress, ContractAddress, _decimals,list) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/priceapi.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
 
      const balance = await contract.getUserTotalTokenValue(currentAddress,list)
  
// ethers转json
let data=balance
let newData = {};
for (const key in data) {
if (data.hasOwnProperty(key)) {
if (typeof data[key] === 'object' && data[key]._isBigNumber) {
newData[key] = data[key].toString();
} else {
newData[key] = data[key];
}
}
}
const arr = Object.values(newData);

let datalist=[]
arr.map(val=>{
 
  var vala = big(val)
    .div(10 ** 18)
    .toFixed();
  datalist.push(vala)
  
})


      return { code: "0", data: datalist };
    } catch (error) {
      //  console.log(error);
    }
  },
  // 
async getUsdtPrices(currentAddress, ContractAddress, _decimals,list) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/priceapi.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
 
      const balance = await contract.getUsdtPrices(list)
     
// ethers转json
let data=balance
let newData = {};
for (const key in data) {
if (data.hasOwnProperty(key)) {
if (typeof data[key] === 'object' && data[key]._isBigNumber) {
newData[key] = data[key].toString();
} else {
newData[key] = data[key];
}
}
}
const arr = Object.values(newData);

let datalist=[]
arr.map(val=>{
 
  var vala = big(val)
    .div(10 ** 8)
    .toFixed();
  datalist.push(vala)
  
})



      return { code: "0", data: datalist };
    } catch (error) {
      //  console.log(error);
    }
  },
 
};
