<template>
  <div class="stakewait">
    <div class="coinInfomation">
      <div class="coinImg"><img :src="dai" class="dailogo" /></div>
      <div class="coinInfo">{{ $t("network.bolck") }}</div>
    </div>
    <Divider style="background: rgba(142, 200, 243, 0.1)" />
    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("Withdraw.Withdrawable") }}:</div>
      <div class="ownMoney">
        {{ formattedDaiOwnMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>
    <div class="inputInfo">
      <input v-model.number="daiValue" type="number" class="inputStyle" />
      <button @click="daiValue = formattedDaiOwnMoney" class="tbnStyle ripple">
        {{ $t("network.all") }}
      </button>
    </div>
    <div class="WithDrawBtn">
      <button class="tbnStyle2 ripple" @click="withdraw" v-if="typeextract == 0">
        {{ $t("Withdraw.Withdraw") }}
      </button>
      <button class="tbnStyle2 ripple" v-else>
        {{ $t("Withdraw.Processing") }}
      </button>
    </div>

    <div class="StakeInfo">
      <div class="StakeResult">{{ $t("Withdraw.Earnings") }}:</div>
      <div class="StakeMoney">
        {{ formattedShareMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>
    <div class="StakeInfo">
      <div class="StakeResult">{{ $t("Withdraw.Brokerage") }}:</div>
      <div class="StakeMoney">
        {{ formattedTemeMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>

    <div class="StakeAuthor">
      <div class="Author">
        <button class="StakeBtn ripple" @click="withdraw2" v-if="typeReinvestment == 0">
          {{ $t("Withdraw.Reinvest") }}
        </button>
        <button class="StakeBtn ripple" v-else>
          {{ $t("Withdraw.Processing") }}
        </button>
      </div>
      <div class="Stakeing">
        <button class="StakeBtn ripple" @click="withdraw3" v-if="typeReceive == 0">
          {{ $t("Withdraw.Getreward") }}
        </button>
        <button class="StakeBtn ripple" v-else>
          {{ $t("Withdraw.Processing") }}
        </button>
      </div>
    </div>
    <div><img class="bStyle" :src="stylea" alt="" /></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <!--loading end-->
  </div>
</template>

<style lang="less" scoped>
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .3s, opacity .5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

.stakewait {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(133deg,
      rgba(7, 110, 150, 0.3),
      rgba(132, 2, 72, 0.6));
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: solid 1px rgba(70, 70, 70, 0.581);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    -o-backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    color: #81c0f1;
    z-index: 10000 !important;
    top: 0;
    left: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .loadimg {
      width: 118px;
      height: 118px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }


  .bStyle {
    width: 290px;
    height: 76px;
  }

  .StakeAuthor {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .StakeBtn {
      width: 136px;
      height: 43px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      color: rgb(2, 0, 0);
      font-size: 20px;
      background-image: linear-gradient(126deg,
          rgba(142, 200, 243, 0.3),
          rgba(250, 51, 158, 0.3));
    }

    .Author {
      width: 136px;
      height: 43px;
    }

    .Stakeing {
      width: 136px;
      height: 43px;
    }
  }

  .StakeInfo {
    display: flex;
    // margin-bottom: 10px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 6px;
    }

    .StakeMoney {
      text-align: right;
      font-size: 20px;
      font-weight: 600;

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .WithDrawBtn {
    .tbnStyle2 {
      width: 100%;
      margin-bottom: 15px;
      height: 50px;
      line-height: 50px;
      position: relative;
      color: rgb(9, 0, 0);
      font-weight: bolder;
      top: 8px;
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 8px;
      padding: 0 10px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
    }
  }

  .inputInfo {
    border: 3px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .inputStyle {
      border: none;
      outline: none;
      width: 220px;
      height: 100%;
      font-size: 20px;
      padding: 0 10px;
      color: #81c0f1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .tbnStyle {
      flex: 1;
      color: rgb(72, 2, 64);
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 0px 5px 5px 0px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 29px;
      height: 29px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      left: 10px;
      font-weight: bold;
    }
  }
}
</style>
<script>
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import loadingimg from "@/assets/img/loading.svg";
import siteapi from "@/json/siteapi.json";
import sign from "@/plugins/sign.js";
// import Plugens from "@/plugins/index";
export default {
  name: "EvWithdraw",
  data() {
    return {
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      daiShareMoney: 0,
      daiTemeMoney: 0.0,
      stylea: stylea,
      usersdata: {},
      typeextract: 0,
      typeReinvestment: 0,
      typeReceive: 0,
      loadingimg: loadingimg,
      isloading: false,
    };
  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== 'undefined') {
      //console.log("Connected wallet");

      // Asynchronously obtaining network information
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chainId) => {
          // Determine whether the current network is a Polygon chain
          if (chainId === '0x89') {
            //console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //console.log("The current network is not Polygon chain");
            this.$router.push({ path: '/' });
            return
          }
        })
        .catch((error) => {
          //console.error('Failed to obtain network information. Procedure:', error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi
    const currentAddress = await web3Utils.connectWallet();
    //console.log(currentAddress)
    this.addresscode = currentAddress
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
      // 签名验证
      async signtype(){
        this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res,'签名验证')
          if(res.code==0){
            this.users()
      this.getMyUserInterest()
          }
          this.isloading = false;
        });
    },
    // User information
    async users() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        //console.log(res, 'User information');
        this.usersdata = res.data
        this.daiOwnMoney = res.data.investAmount * 1
        this.daiTemeMoney = res.data.rewardAmount * 1
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    // Personal income
    async getMyUserInterest() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.getMyUserInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        //console.log(res, 'Personal income');
        this.daiShareMoney = res.data * 1
        //console.log(this.daiShareMoney)
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    //withdraw button
    withdraw() {
      if (this.daiValue <= 0) {

        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo1")
        });
        return
      }
      if (this.daiValue > this.daiOwnMoney) {

        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo2")
        });
        return
      }
      this.isloading = true;
      this.withdrawfun();
      // this.warning(false);
    },
    // withdraw
    async withdrawfun() {

      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeextract = 1;
      try {
        await contract.withdraw(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//Fractional part

        ).then((res) => {
          //console.log(res);
          this.typeextract = 0;
          if (res.code == 0) {
            this.typeextract = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")

            });
            this.users()
            this.getMyUserInterest()
          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            if(res.code<1){
                  this.$Notice.warning({
                    title: res.error.message
                  });
             }else{
                  this.$Notice.warning({
                    title: this.$t("Withdraw.unsuccessfule")
                    // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                  });
             }
          }

        });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    withdraw2() {
      var value = this.daiShareMoney + this.daiTemeMoney
      //console.log(value)
      if (value >= 0) {
        //console.log(123);
        this.isloading = true;
        this.depositInterestAndReward()
      } else {
        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo2")
        });
      }
      // this.warning2(false);
    },
    // reinvestment
    async depositInterestAndReward() {
      //console.log("reinvestment")
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReinvestment = 1;
      try {
        await contract.depositInterestAndReward(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//float

        ).then((res) => {
          //console.log(res);
          this.typeReinvestment = 0;
          if (res.code == 0) {
            this.typeReinvestment = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()
            this.getMyUserInterest()
          } else {
            this.isloading = false;
            
            if(res.code<1){
                  this.$Notice.warning({
                    title: res.error.message
                  });
              }else{
                  this.$Notice.warning({
                    title: this.$t("Withdraw.unsuccessfule")
                    // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                  });
              }
            
            
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    withdraw3() {
      var value = this.daiShareMoney + this.daiTemeMoney
      //console.log(value)
      if (value >= 0) {
        this.isloading = true;
        this.withdrawInterestAndReward()
      } else {
        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo2")
        });
      }
    },
    // withdraw
    async withdrawInterestAndReward() {
      //console.log("withdraw")
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReceive = 1;
      try {
        await contract.withdrawInterestAndReward(
          Address, //Contract address
          this.daiValue, //number
          this.addresscode,//current address
          _decimals,//float

        ).then((res) => {
          //console.log(res);
          this.typeReceive = 0;
          if (res.code == 0) {
            this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()
            this.getMyUserInterest()
          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            
              if(res.code<1){
                  this.$Notice.warning({
                    title: res.error.message
                  });
              }else{
                  this.$Notice.warning({
                    title: this.$t("Withdraw.unsuccessfule")
                    // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                  });
              }
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.withdraw"),
      });
    },
    warning2(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Reinvest"),
      });
    },
    warning3(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Getreward"),
      });
    },
  },

  computed: {
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      //console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value
    },
    formattedShareMoney() {
      if (!this.daiShareMoney) return "0.00";
      const multipliedNumber = this.daiShareMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney() {
      if (!this.daiTemeMoney) return "0.00";
      const multipliedNumber = this.daiTemeMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
  },
};
</script>
