import { render, staticRenderFns } from "./EvTeam.vue?vue&type=template&id=421cc242&scoped=true"
import script from "./EvTeam.vue?vue&type=script&lang=js"
export * from "./EvTeam.vue?vue&type=script&lang=js"
import style0 from "./EvTeam.vue?vue&type=style&index=0&id=421cc242&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421cc242",
  null
  
)

export default component.exports