import big from "big.js";
import { ethers } from "ethers";
import Web3 from "web3";
import bigInt from "big-integer";
import ERC20ABI from "../json/ERC20ABI.json";
import i18n from "../utils/i18n";
// import UniswapRouter02Contract from "~/json/UniswapRouter02Contract.json";

export default {
  //  授权数量
  async allowancetoken(
    tokenAddress,
    amountADesired,
    CurrentAccount,
    spender,
    _decimals
  ) {
    try {

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(tokenAddress, ERC20ABI, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.allowance(CurrentAccount, spender)


        let amountAout = big(balance.toString())
        .div(10 ** 18)
        .toFixed()

      return { code: "0", data: amountAout };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };


    }
  },
   //  余额
   async balanceOf(
    tokenAddress,
    amountADesired,
    CurrentAccount,
    _decimals
  ) {
    try {

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(tokenAddress, ERC20ABI, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.balanceOf(CurrentAccount)


        let amountAout = big(balance.toString())
        .div(10 ** _decimals)
        .toFixed()

      return { code: "0", data: amountAout };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };


    }
  },
  //   授权
  async approveTokens(
    tokenAddress,
    amountADesired,
    CurrentAccount,
    spender,
    _decimals
  ) {
    try {
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // amountADesired
      // const amountApproved = big(amountADesired)
      const value = big(1000000)
        .times(10 ** _decimals)
        .toFixed();
      const bigNumber = bigInt(value);
      //  console.log(bigNumber.value);
      const amountApproved = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
      // const bigNumber = BigInt("12345678901234567890");
      //         const bigNumber = BigInt("12345678901234567890");
      // const stringNumber = bigNumber.toString();
      const tokebContract = new web3.eth.Contract(ERC20ABI, tokenAddress);
      const approveFunction = tokebContract.methods.approve(
        spender,
        amountApproved
      ); // 替换为您要授权的代币数量
      // gas
      const getGasPrice=await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();

      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });

      await tokebContract.methods.approve(spender, amountApproved).send({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
        gasLimit: hexValue,
        gas: hexValue,
        gasPrice: Math.round(getGasPriceto*2),
      });

      //   await tokebContract.methods
      //     .approve(spender, amountApproved)
      //     .send({ from: CurrentAccount, gasLimit: hexValue });
      return { code: "0" };
    } catch (error) {
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }

        if(errorMessage.includes("too many arguments")){
            errorCode ="-1";
            errorMessage =i18n.t("ido.message");
        }

        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }

        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };


    }
  },
  //   //   预估
  //   async estimateGas(
  //     tokenAddress,
  //     amountADesired,
  //     CurrentAccount,
  //     spender,
  //     ){
  //         try {
  //             console.log(amountADesired)
  //             console.log(CurrentAccount)
  //             console.log(spender)
  //     const ethereum = window.ethereum;
  //     const web3 = new Web3(Web3.givenProvider || ethereum);
  //     const amountApproved = big(amountADesired)
  //     .times(10 ** 18)
  //     .toFixed();
  // console.log(amountApproved)
  // const tokebContract = new web3.eth.Contract(ERC20ABI, tokenAddress, {
  //     from: CurrentAccount,
  //   });
  // const approveFunction = tokebContract.methods.approve(CurrentAccount, amountApproved); // 替换为您要授权的代币数量
  // console.log(approveFunction)
  // // 定义转账数量（以最小单位表示，例如wei）
  // // const amount = web3.utils.toWei(amountApproved, 'ether');

  // // 构建转账交易对象
  // const transactionObject = {
  //   from: CurrentAccount,
  //   to: tokenAddress,
  //   data: approveFunction.encodeABI(),
  // };
  // console.log(transactionObject)
  // // 估算 gas
  // var gasdata=web3.eth.estimateGas(transactionObject)
  // //   .then(gas => {
  // //     console.log('估算的 gas 量:', gas);
  // //     gasdata=gas
  // //   })
  // //   .catch(error => {
  // //     console.error('估算 gas 量时出错:', error);
  // //   });

  // console.log(gasdata,'gasdata')
  // return gasdata
  // } catch (error) {
  //     console.log(error);
  //     return error;
  //   }

  //   },
};
