const en = {
  network: {
    tips: `The selected network is not currently supported!
    Please select the Matic (Polygon) network!'`,
    ev: "Please check whether you have installed any wallet software such as MetaMask, Trust Wallet, imToken, or TokenPocket.",
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Investor Notice: This Distributed Application (DApp) carries uncertain investment risks. National policies and regulatory laws might strictly control and limit blockchain technology and related investments. Additionally, the price of digital currencies is highly volatile, potentially causing investors to bear significant investment risks. Before participating in investment, investors should fully understand and assess the risks of investment, making decisions cautiously. We strongly advise investors to invest rationally and avoid following 
    the herd blindly, preventing irreversible 
    financial losses.`,
    protocol: `This Distributed Application (DApp) harbors uncertain investment risks. Before use, please ensure you read and fully understand the risk warnings and privacy policy.`,
    more: "More",
    media: "Related Social Media",
    stake:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    withdraw:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Reinvest:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Getreward:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    linkwallet: "Connect Wallet",
    notification: "This feature is coming soon! Stay tuned for community announcements.",
    flashloansOK:'Start-up successful!',
    flashloansNo:'Start-up failed!',
    flashloansOnce:'Congratulations on getting one chance to experience!',
    flashloansError:'Sorry, the opportunity to experience has been used up!',
    falocnerror:'Falcon™ V2 Test qualification not granted!',
    falocninfo:'Falcon™ V2 for players approved at V3 level! Currently in public beta!',
    falconv4:'Falcon™V4 arbitrage cluster is in beta testing (28000 times/day)!'
  },
  leavel: {
    0:"seed",
    1: "Blackcurrant",
    2: "Cherries",
    3: "DragonFruit",
    4: "Hazelnut",
    5: "Noni",
    6: "Persimmon",
    7: "Truffle",
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"
  },
  top: {
    Account: "Account",
  },
  stake: {
    Balance: "Your Balance",
    Total: "Total Stake",
    Appoove: "Approve",
    Stake: "Stake",
    Appooveing: "Authorizing",
    AuthorSuccessful: "Authorization Successful",
    AuthorFailed: "Authorization Failed",
    Stakeing: "Depositing",
    StakeSuccessful: "Deposit Successful",
    StakeFailed: "Deposit Failed",
    StakeInfo: "Cannot be Less Than:",
  },
  swap:{
    Swap:"SWAP",
    paynameA:"Payment",
    paynameB:"Receipt",
    Approve:"Authorization",
    launchSwap:"Launch SWAP",
    recommend:"Recommend",
    swapError:"Swap error",
    swapNetwork:"Network error",
  },
  menu: {
    Home: "Home",
    Language: "Language",
    Document: "WhitePaper",
    Contract: "Contract",
    Falcon:"Falcon™",
    Watch: "Watch",
    Coming: "Coming Soon",
    EvDao: "Governance",
    Flashloan: "Launch",
    Developer:"Developer Docs",
    Launch:"Launch",
    xNumber:"Remaining",
    xTips:"Automatic processing",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    swap:"Swap",   
    LogiV2pro:"Falcon™ V2+",
    LogiV3tips:"Falcon™ V3 global public testing begins (daily requests 1000~7000), authorization required for the corresponding arbitrage contracts!",
    Appoove2:"Auto",
    Market:"Market",
    Ido:"Falcon IDO",
    myIdo:"My Falcon"
  },
  Withdraw: {
    Withdraw: "Withdraw",
    Withdrawable: "Withdrawable",
    Earnings: "Earnings",
    Brokerage: "Brokerage amount",
    Reinvest: "Reinvest",
    Getreward: "Get Reward",
    Processing: "Processing",
    unsuccessfule: "Unsuccessfule",
    successful: "Successful!",
    WithdrawInfo1: "Please Enter the Withdrawal Amount",
    WithdrawInfo2: "Insufficient Quantity",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team staked",
    leavel: "Honor",
    Team: "Team",
    Bind: "Bind Relation",
    info: "System temporarily paused binding, please wait for it to open.",
    Binding: "Binding in Progress",
    BindingSuccessful: "Binding Successful",
    BindingFailed: "Binding Failed",
    Bindinfo: "Please Enter the Inviter's Wallet Address",
  },
  Share: {
    Invitelink: "Invite Link",
    Intips: `The Dapp invitations are not currently available. 
            Please wait for the latest comImunity updates.`,
    copyTitle: "Invitation link",
    copyText: "The invitation link has been successfully copied!",
    copyCommnad: "Copy Link",
  },
  Contract: {
    contractaddress: "Contract Address",
    Copylink: "Polygonscan",
    CopylinkText: "Go to polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Watch-only wallet",
    addwallet: "Add wallet",
    add: "Add",
    Leavel: "Leavel",
    share: "Share",
    fnOpen:
      "This wallet has not been invited to participate in this feature yet!！",
      nullString: "Please enter the wallet address!",
      addok:"Added successfully",
      addError:"Addition failed",
      addisExists:"This address already exists",
      addlist:"Watchlist",
  },
};
export default en;
