import { render, staticRenderFns } from "./EvFalocnV4.vue?vue&type=template&id=5931cac3&scoped=true"
import script from "./EvFalocnV4.vue?vue&type=script&lang=js"
export * from "./EvFalocnV4.vue?vue&type=script&lang=js"
import style0 from "./EvFalocnV4.vue?vue&type=style&index=0&id=5931cac3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5931cac3",
  null
  
)

export default component.exports