const vi = {
  network: {
    tips: `Mạng lựa chọn hiện không được hỗ trợ!
      Vui lòng chọn mạng Matic (Polygon)!`,
    ev: "Vui lòng kiểm tra liệu bạn đã cài đặt phần mềm ví như MetaMask, Trust Wallet, imToken, hay TokenPocket chưa.",
    copyight: "FlashMonkey",
    all: "Tối đa",
    bolck: "Đồng tiền ổn định Dai",
    law: `Thông báo cho nhà đầu tư: Ứng dụng phân quyền (DApp) này mang rủi ro đầu tư không chắc chắn. Các chính sách quốc gia và luật pháp kiểm soát và hạn chế công nghệ blockchain và các khoản đầu tư liên quan. Ngoài ra, giá của các loại tiền số biến động mạnh, có thể khiến nhà đầu tư phải chịu rủi ro đầu tư lớn. Trước khi tham gia đầu tư, nhà đầu tư nên hiểu rõ và đánh giá rủi ro đầu tư, và ra quyết định một cách cẩn thận. Chúng tôi khuyên nhà đầu tư nên đầu tư một cách có lý và tránh theo đuổi đám đông một cách mù quáng, nguy cơ mất mát tài chính không thể đảo ngược.`,
    protocol: `Ứng dụng phân quyền (DApp) này mang rủi ro đầu tư không chắc chắn. Trước khi sử dụng, hãy đảm bảo bạn đã đọc và hiểu rõ cảnh báo rủi ro và chính sách bảo mật.`,
    more: "Thêm",
    media: "Truyền thông xã hội liên quan",
    stake:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    withdraw:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    Reinvest:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    Getreward:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    linkwallet: "Kết nối ví",
    notification: "Tính năng này sắp được ra mắt! Chú ý đến thông báo cộng đồng",
    flashloansOK:'Khởi động thành công!',
    flashloansNo:'Khởi động thất bại!',
    flashloansOnce:'Chúc mừng bạn đã nhận được 1 cơ hội trải nghiệm!',
    flashloansError:'Xin lỗi, cơ hội trải nghiệm đã được sử dụng hết!',
    falocnerror:'Falcon™ V2 Không được cấp quyền kiểm tra!',
    falocninfo:'Falcon™ V2 dành cho người chơi được phê duyệt ở cấp độ V3! Hiện đang trong giai đoạn thử nghiệm công khai!',
    falconv4:'Cụm chênh lệch giá Falcon™V4 đang trong giai đoạn thử nghiệm nội bộ (28000 lần/ngày)!'
  },
  leavel: {
    0:"Hạtgiống",
    1: "Quảlýchuađen",
    2: "Quảanhđào",
    3: "Thanhlong",
    4: "Hạtphỉ",
    5: "Noni",
    6: "Hồng",
    7: "Nấmtruffle",
  },
  top: {
    Account: "Tài khoản",
  },
  swap:{
    Swap:"Đổi",
    paynameA:"Thanh toán",
    paynameB:"Nhận tiền",
    Approve:"Ủy quyền",
    launchSwap:"Khởi động trao đổi",
    recommend:"Đề xuất",
    swapError:"Lỗi đổi",
    swapNetwork:"Lỗi mạng",
  },
  stake: {
    Balance: "Số dư của bạn",
    Total: "Tổng cược",
    Appoove: "Chấp nhận",
    Stake: "Cược",
    Appooveing: "Đang ủy quyền",
    AuthorSuccessful: "Ủy quyền thành công",
    AuthorFailed: "Ủy quyền thất bại",
    Stakeing: "Đang gửi tiền",
    StakeSuccessful: "Gửi tiền thành công",
    StakeFailed: "Gửi tiền thất bại",
    StakeInfo: "Không thể Nhỏ hơn:",
  },
  menu: {
    Home: "Trang chủ",
    Language: "Ngôn ngữ",
    Document: "Sách Trắng",
    Contract: "Hợp đồng",
    Falcon:"Falcon™",
    Watch: "Theo dõi",
    Coming: "Sắp ra mắt",
    EvDao: "BULLDAO",
    Flashloan: "Flashloan",
    Developer:"Developer Docs",
    Launch:"Launch",
    xNumber:"Còn lại",
    xTips:"Xử lý tự động",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Falcon™ V3 bắt đầu thử nghiệm công cộng toàn cầu (yêu cầu hàng ngày 1000~7000), cần phải có sự ủy quyền cho hợp đồng đầu cơ tương ứng!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon"
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "Rút tiền",
    Withdrawable: "Có thể rút",
    Earnings: "Lợi nhuận",
    Brokerage: "Số tiền môi giới",
    Reinvest: "Tái đầu tư",
    Getreward: "Nhận phần thưởng",
    Processing: "Đang xử lý",
    unsuccessfule: "Không thành công",
    successful: "Thành công!",
  },
  Team: {
    innver: "Nội tại",
    Teamstaked: "Đội cược",
    leavel: "Cấp độ",
    Team: "Đội",
    Bind: "Gắn kết mối quan hệ",
    info: "Hệ thống tạm thời tạm ngừng kết nối, vui lòng đợi để mở lại.",
    Binding: "Đang Kết nối",
    BindingSuccessful: "Kết nối Thành công",
    BindingFailed: "Kết nối Thất bại",
    Bindinfo: "Vui lòng nhập địa chỉ ví của người mời",
  },
  Share: {
    Invitelink: "Liên kết mời",
    Intips: `Lời mời Dapp hiện không khả dụng. 
              Vui lòng chờ cập nhật mới nhất từ cộng đồng.`,
    copyTitle: "Liên kết mời",
    copyText: "Liên kết mời đã được sao chép thành công!",
    copyCommnad: "Sao chép liên kết",
  },
  Contract: {
    contractaddress: "Địa chỉ hợp đồng",
    Copylink: "Polygonscan",
    CopylinkText: "Chuyển đến polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Ví chỉ xem",
    addwallet: "Thêm ví",
    add: "Thêm",
    Leavel: "Cấp độ",
    share: "Chia sẻ",
    fnOpen: "Ví này chưa được mời tham gia tính năng này!",
    nullString: "Vui lòng nhập địa chỉ ví!！",
    addok:"Thêm thành công",
    addError:"Thêm thất bại",
    addisExists:"Địa chỉ này đã tồn tại",
    addlist:"Danh sách theo dõi",
  },
};
export default vi;
