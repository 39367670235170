const ru = {
  network: {
    tips: `Выбранная сеть в настоящее время не поддерживается!
      Пожалуйста, выберите сеть Matic (Polygon)!`,
    ev: `Пожалуйста, проверьте, установлен ли у вас кошелек, такой как MetaMask,
     Trust Wallet, imToken, или TokenPocket.`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Уведомление для инвесторов: Это децентрализованное приложение (DApp) несет в 
    себе неопределенные инвестиционные риски. Национальные политики и регулятивные законы 
    могут строго контролировать и ограничивать технологии блокчейна и сопутствующие 
    инвестиции. Кроме того, цены на цифровые валюты сильно колеблются, что может привести 
    к существенным инвестиционным рискам для инвесторов. Прежде чем принимать участие в 
    инвестициях, инвесторы должны полностью понимать и оценивать риски, связанные с 
    инвестициями, и принимать решения осмотрительно. Мы настоятельно рекомендуем инвесторам 
    инвестировать рационально и избегать слепого следования за массами, чтобы предотвратить 
    необратимые финансовые потери.`,
    protocol: `Это распределенное приложение (DApp) имеет неопределенные инвестиционные риски. Перед использованием, пожалуйста, внимательно прочитайте и полностью поймите предупреждения о рисках и политику конфиденциальности.`,
    more: "подробности",
    media: "соответствующие социальные сети",
    stake:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    withdraw:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    Reinvest:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    Getreward:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    linkwallet: "Подключить кошелек",
    notification: "Эта функция скоро будет запущена! Следите за уведомлениями сообщества",
    flashloansOK:'Запуск успешен! ',
    flashloansNo:'Запуск не удался!',
    flashloansOnce:'Поздравляем с получением одного шанса на опыт!',
    flashloansError:'Извините, возможность опыта уже использована!',
    falocnerror:'Falcon™ V2 Тестовая квалификация не одобрена!',
    falocninfo:'Falcon™ V2 для игроков, одобренных на уровне V3! В настоящее время находится на стадии публичного тестирования!!',
    falconv4:'Арбитражный кластер Falcon™V4 находится в стадии бета-тестирования (28000 раз/день)!'
  },
  leavel: {
    0:"Семя",
    1: "Чёрная смородина",
    2: "Вишня",
    3: "Драконий фрукт",
    4: "Лесной орех",
    5: "Нони",
    6: "Хурма",
    7: "Трюфель",
  },
  top: {
    Account: "Аккаунт",
  },
  swap:{
    Swap:"Обмен",
    paynameA:"Платеж",
    paynameB:"Получение платежа",
    Approve:"Авторизация",
    launchSwap:"Начать обмен",
    recommend:"Рекомендация",
    swapError:"Ошибка обмена",
    swapNetwork:"Сетевая ошибка",
  },
  stake: {
    Balance: "Ваш Баланс",
    Total: "Общая Ставка",
    Appoove: "Утвердить",
    Stake: "Ставка",
    info: "Система временно приостановила привязку, пожалуйста, подождите, пока она не откроется.",
    Appooveing: "Авторизация",
    AuthorSuccessful: "Авторизация Успешно",
    AuthorFailed: "Авторизация Не Удалась",
    Stakeing: "Внесение",
    StakeSuccessful: "Успешное Внесение",
    StakeFailed: "Внесение Не Удалось",
    StakeInfo: "Не может быть Меньше, чем:",
  },
  menu: {
    Home: "Главная",
    Language: "Язык",
    Document: "Белая книга",
    Contract: "Контракт",
    Watch: "Наблюдение",
    Coming: "Скоро",
    EvDao: "BULLDAO",
    Flashloan: "Запуск Flashloan",
    Developer:"Документация для разработчиков",
    Launch:"Launch",
    xNumber:"Оставшийся",
    Falcon:"Falcon™",
    xTips:"Автоматическая обработка",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Начинается глобальное публичное тестирование Falcon™ V3 (ежедневные запросы 1000~7000), требуется авторизация соответствующих арбитражных контрактов!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon"
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "Вывести",
    Withdrawable: "Доступно для вывода",
    Earnings: "Доход",
    Brokerage: "Сумма брокерского вознаграждения",
    Reinvest: "Реинвестировать",
    Getreward: "Получить награду",
    Processing: "Обработка",
    unsuccessfule: "Неуспешно",
    successful: "Успешно!",
    WithdrawInfo1: "Пожалуйста, введите количество для снятия",
    WithdrawInfo2: "недостаточное",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Ставки команды",
    leavel: "Уровень",
    Team: "Команда",
    Bind: "Привязать отношения",
    info: "Система временно приостановила пригла",
    Binding: "Процесс Привязки",
    BindingSuccessful: "Привязка Успешна",
    BindingFailed: "Привязка Не Удалась",
    Bindinfo: "Пожалуйста, введите адрес кошелька пригласившего",
  },
  Share: {
    Invitelink: "Ссылка для приглашения",
    Intips:
      "Приглашения в Dapp в настоящее время недоступны. Пожалуйста, ждите последних обновлений от сообщества.",
    copyTitle: "Ссылка для приглашения",
    copyText: "Ссылка для приглашения успешно скопирована!",
    copyCommnad: "Скопировать ссылку",
  },
  Contract: {
    contractaddress: "Адрес контракта",
    Copylink: "Polygonscan",
    CopylinkText: "Перейти к polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Кошелек только для наблюдения",
    addwallet: "Добавить",
    add: "Добавить",
    Leavel: "Уровень",
    share: "Поделиться",
    fnOpen: "Этот кошелек еще не был приглашен участвовать в этой функции!",
    nullString: "Пожалуйста, введите адрес кошелька!",
    addok:"Добавление выполнено",
    addError:"Добавление не удалось",
    addisExists:"Этот адрес уже существует",
    addlist:"Список наблюдения",
  },
};
export default ru;
