import { Select } from "view-design";

const zhTW = {
  network: {
    tips: `目前不支援所選的網路！請選擇 Matic (Polygon) 網路！`,
    ev: `請檢測是否安裝 \nMetaMask,\nTrustWallet,imToken,TokenPocket\n等錢包中任意一款錢包軟體.`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `投資者須知：本分佈式應用程序（DApp）存在不確定的投資風險。國家政策和監管法規對於區塊鏈技術和相關投資可能有嚴格的控制和限制。此外，數字貨幣的價格極易波動，可能導致投資者承受巨大的投資風險。投資者在參與投資之前，
    應充分了解和評估投資的風險，並謹慎做出決策。我們強烈建議投資者理性投資，
    避免盲目跟風，以免造成不可挽回的經濟損失。`,
    protocol: `本分佈式應用程序（DApp）潛藏不確定的投資風險。在使用前，請您務必細致閱讀並充分理解風險提示及隱私條款。`,
    more: "詳情",
    media: "相關社交媒體",
    stake: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    withdraw: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    Reinvest: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    Getreward: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    linkwallet: "连接钱包",
    notification: "即將推出此功能！關注社區通知！",
    flashloansOK:'啟動成功!',
    flashloansNo:'啟動失敗!',
    flashloansOnce:'恭喜您獲得1次體驗機會!',
    flashloansError:'抱歉，體驗機會已使用完畢!',
    falocnerror:'Falcon™ V2未獲准測試資格',
    falocninfo:'Falcon™ V2 針對3級獲批的玩家！現處於公測階段!',
    falconv4:'Falcon™V4套利集群正在內測中（28000次/日）！'
  },
  leavel: {
    0:"種子",
    1: "黑加侖",
    2: "车厘子",
    3: "火龍果",
    4: "榛子",
    5: "諾麗果",
    6: "牛心柿",
    7: "松露",
  },
  top: {
    Account: "帳戶",
  },
  swap:{
    Swap:"兌換",
    paynameA:"付款",
    paynameB:"收款",
    Approve:"授權",
    launchSwap:"啟動交換",
    recommend:"推薦",
    swapError:"兌換發生錯誤",
    swapNetwork:"網絡發生錯誤",
  },
  stake: {
    Balance: "錢包",
    Total: "己經存入",
    Appoove: "授權",
    Stake: "存入",
    Appooveing: "授權中",
    AuthorSuccessful: "授權成功",
    AuthorFailed: "授權失敗",
    Stakeing: "存入中",
    StakeSuccessful: "存入成功",
    StakeFailed: "存入失敗",
    StakeInfo: "不能小於:",
    Contract:"套利合約",
    Appoove2:"自動"
  },
  menu: {
    Home: "首頁",
    Language: "語言",
    Document: "文檔",
    Contract: "合約",
    Watch: "觀察",
    Coming: "即將發佈",
    EvDao: "社區治理",
    Flashloan: "啟動閃電貸",
    Developer:"開發者文檔",
    Launch:"啟動",
    xNumber:"剩餘",
    Falcon:"Falcon™",
    xTips:"自動處理",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    swap:"閃兌",
    Market:"市場",
    LogiV2pro:"Falcon™ V2+",
    LogiV3tips:"Falcon™ V3全球公測開始（日請求1000~7000次），需授權相應的套利合約！",
    Appoove2:"自動",
    Ido:"Falcon認購",
    myIdo:"我的認購"
  },
  ido:{
    select: "選擇社區",
    flashmonkey: "閃電猴",
    flashdragon: "閃電龍",
    fashFalcon: "Falcon",
    powermonger: "寶龍",
    fashfalcon2: "FalconV2",
    other: "其它",
    Getmax: "獲取最大認購額度",
    nowbuy: "立即認購",
    mynumber: "我的認購總額",
    myrelease: "已釋放",
    waitrelease: "待釋放",
    get: "提取",
    FalconF: "閃電鷹F",
    FalconA: "閃電鷹A",
    organization: "機構",
    endTimes:'第一輪倒計時',
    getmax:'獲取最大額度',
    Authorization:"授權",
    Subscribe:"立即認購",
    okSubscribe:"已認購",
    message:"節點錯誤，請更換節點",
    end1:"第一輪已結束",
    needStart:"距離開始",
    getscusse:"獲取成功",
    geterror:"獲取失敗"

  },
  Withdraw: {
    Withdraw: "提取",
    Withdrawable: "可提取",
    Earnings: "個人收益",
    Brokerage: "生態收益",
    Reinvest: "再資",
    Getreward: "獲取獎勵",
    Processing: "處理中",
    unsuccessfule: "處理失敗!",
    successful: "處理成功！",
    WithdrawInfo1: "請輸入提取數量",
    WithdrawInfo2: "數量不足",
  },
  Team: {
    innver: "邀請",
    Teamstaked: "生態",
    leavel: "榮譽",
    Team: "生態",
    Bind: "綁定關係",
    info: "系統暫停綁定，請等待開放！",
    Binding: "綁定中",
    BindingSuccessful: "綁定成功",
    BindingFailed: "綁定失敗",
    Bindinfo: "请输入邀请人钱包地址",
  },
  Share: {
    Invitelink: "邀請鏈接",
    Intips: "Dapp邀請目前不可用。請等待最新社區更新。",
    copyTitle: "邀請鏈接",
    copyText: "邀請鏈接複製成功！",
    copyCommnad: "複製連結",
  },
  Contract: {
    contractaddress: "合約地址",
    Copylink: "Polygonscan",
    CopylinkText: "前往polygonscan",
  },
  Wathch: {
    watchonlywallet: "僅觀看錢包",
    addwallet: "添加觀察錢包",
    add: "添加",
    Leavel: "榮譽",
    share: "分享",
    fnOpen: "暫未邀請此錢包參與此功能！",
    nullString: "請輸入錢包地址！",
    addok:"添加成功",
    addError:"添加失败",
    addisExists:"此地址己存在",
    addlist:"觀察列表",
    
  },
};
export default zhTW;
